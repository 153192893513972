/* global window */
/* global ga */
/* global dataLayer */

import { Controller } from '@hotwired/stimulus';
import { DateTime } from '@easepick/datetime';

export default class extends Controller {
  static targets = ["clientId", "form"]

  static values = {
    "artist-name": String,
    "email-regex": String,
    "categories": String,
    translations: Object
  }

  connect () {
    this.setupGoogleAnalytics();
    this.setupForm();
  }

  setupGoogleAnalytics () {
    var self = this;

    this.GoogleAnalytics = {
      // Returns true if Google Analytics is loaded
      isEnabled: function() {
        return (typeof(window.ga) != "undefined")
      },

      setClientId: function() {
        // assign value to the hidden client id field to track successful booking
        // events serverside
        if(self.GoogleAnalytics.isEnabled()) {
          window.ga(self.GoogleAnalytics.updateClientIdField);
        }
      },

      updateClientIdField: function() {
        var trackerClients = window.ga.getAll()
        if (trackerClients) {
          var field = self.clientIdTarget
          for (var i = 0; i < trackerClients.length; i++) {
            var clientId = trackerClients[i].get('clientId')
            if (clientId && !field.val()) {
              field.val(clientId)
              clearInterval(self.GoogleAnalytics.timer)
            }
          }
        }
      },

      updateClientIdFieldWhenPossible: function() {
        self.GoogleAnalytics.timer = setInterval(self.GoogleAnalytics.setClientId, 250)
      }
    }
  }

  setupClientIdFetcher() {
    this.GoogleAnalytics.updateClientIdFieldWhenPossible()
  }

  setupEventlisteners () {
    var self = this;

    this.formTarget.addEventListener("ajax:beforeSend", function(_event) {
      this.setupClientIdFetcher()
      this.formTarget.classList.add('is-submitted');
    }.bind(this))

    this.formTarget.addEventListener("ajax:success", function(event) {
      const xhr = event.detail[2];

      self.element.innerHTML = xhr.responseText;

      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          'event': 'eventCreated',
          'eventCategory': self.categoriesValue,
          'eventArtistName': self.artistNameValue
        });
      }
    })

    this.formTarget.addEventListener("ajax:error", function(event) {
      const xhr = event.detail[2];
      self.element.innerHTML = xhr.responseText;
      self.setupForm();
    })
  }

  setupValidations () {
    var self = this;
    const emailRegex = self.emailRegexValue;
    const translations = self.translationsValue;

    this.Validations = {
      // ValidDate not currently run - should probably be added with our datepicker controller
      validDate: function(element) {
        var chosenDate = new DateTime(element.value, "DD-MM-YYYY");

        if (!chosenDate) {
          return self.Validations.reset(element);
        }

        var today = new DateTime();

        if (chosenDate >= today) {
          self.Validations.validInput(element)
        } else {
          self.Validations.invalidInput(element, translations.invalid_date)
        }
      },

      validateAlwaysValid: function(element) {
        if (element.value == "") {
          self.Validations.reset(element);
        } else {
          self.Validations.validInput(element);
        }
      },

      validateContactName: function(element) {
        if (element.value != "") {
          self.Validations.validInput(element);
        } else {
          self.Validations.invalidInput(element, translations.invalid_contact_name)
        }
      },

      validateContactEmail: function(element) {
        let contactEmail = element.value.trim();
        if (self.Validations.validateEmail(contactEmail)) {
          self.Validations.validInput(element);
        } else {
          self.Validations.invalidInput(element, translations.invalid_contact_email)
        }
      },

      validateContactPhone: function(element) {
        if (element.intlTelInput) {
          // Use intlTelInput validations
          if (element.intlTelInput.isValidNumber()) {
            self.Validations.validInput(element);
          } else {
            self.Validations.invalidInput(element, translations.invalid_contact_phone)
          }
        } else {
          // Use the old, naive validations
          contactPhone = element.value;
          if (Validations.validatePhone(contactPhone)) {
            self.Validations.validInput(element);
          } else {
            self.Validations.invalidInput(element, translations.invalid_contact_phone)
          }
        }
      },

      validInput: function(element) {
        self.Validations.reset(element);

        const parentFormGroup = self.Validations.parents(element, '.form-group-wrapper')[0]
        parentFormGroup.classList.add('valid-input');
      },

      reset: function(element) {
        const parentFormGroup = self.Validations.parents(element, '.form-group-wrapper')[0]
        parentFormGroup.classList.remove("has-error");
        parentFormGroup.classList.remove("valid-input");
      },

      invalidInput: function(element, msg) {
        self.Validations.reset(element);

        const parentFormGroup = self.Validations.parents(element, '.form-group-wrapper')[0]
        parentFormGroup.classList.add('has-error');
        let helpBlock = parentFormGroup.querySelectorAll(':scope .help-block')[0]

        if (helpBlock) {
          helpBlock.remove();
        }

        let invalidFeedBackElement = document.createElement('span');
        invalidFeedBackElement.classList.add('help-block');
        invalidFeedBackElement.classList.add('invalid-feedback');
        invalidFeedBackElement.textContent = msg;

        parentFormGroup.append(invalidFeedBackElement);
      },

      parents (element, selector) {
        const parents = [];
        while ((element = element.parentNode) && element !== document) {
          if (!selector || element.matches(selector)) parents.push(element);
        }
        return parents;
      },

      validatePhone: function(number) {
        stripped_number = self.Validations.stripNumber(number);

        if (stripped_number.length > 0) {
          return true;
        }
        return false;
      },

      validateEmail: function(email) {
        var re = new RegExp(emailRegex);

        return re.test(email);
      },

      stripNumber: function(number) {
        // remove beginning and trailing whitespace
        stripped_number = number.trim();

        // remove whitespace from within the resulting number
        stripped_number = stripped_number.replace(/\s+/g, '');

        return stripped_number;
      }
    }
  }

  setupForm () {
    const self = this;

    this.setupEventlisteners()
    this.setupClientIdFetcher()
    this.setupValidations();

    var validations = [
      ['[name="booking_event[event_date]"]', [this.Validations.validDate], "select"],
      ['[name="booking_event[event_time]"]', [this.Validations.validateAlwaysValid]],
      ['[name="booking_event[event_kind]"]', [this.Validations.validateAlwaysValid]],
      ['[name="booking_event[event_location]"]', [this.Validations.validateAlwaysValid]],
      ['[name="booking_event[event_size]"]', [this.Validations.validateAlwaysValid]],
      ['[name="booking_event[contact_name]"]', [this.Validations.validateContactName]],
      ['[name="booking_event[contact_email]"]', [this.Validations.validateContactEmail]],
      ['[name="booking_event[contact_phone_number]"]', [this.Validations.validateContactPhone]]
    ];

    validations.forEach(function(validationConfig) {
      var elements = self.element.querySelectorAll(validationConfig[0]);
      var validationsToAdd = validationConfig[1];
      var bindEvent = validationConfig[2] || "change";

      validationsToAdd.forEach(function(validationFunction, _index) {
        elements.forEach(function(element) {
          if (element.value) {
            validationFunction(element)
          }

          element.addEventListener(bindEvent, function(event) {
            validationFunction(event.target)
          })
        })
      })
    })
  }
}
