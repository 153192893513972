/* global window */

import { Controller } from '@hotwired/stimulus';

// Targets
//
// * input: The input field that will be used for the autocomplete.
//
// * postalCode: The value of this element will be set to the postal code of the
//   place selected in the autocomplete dropdown.
export default class extends Controller {
  static targets = [
    "input",
    "postalCode",
  ];

  triggerDropdown() {
    this.inputTarget.blur();
    this.inputTarget.focus();
  }

  addressGoogleCallback () {
    window.googleMapsIsLoaded = true;

    this.setup();

    if (this.isInputElementFocused()) {
      setTimeout(this.triggerDropdown.bind(this), 100);
    }
  }

  connect () {
    window.addressGoogleCallback = this.addressGoogleCallback.bind(this);
  }

  isInputElementFocused () {
    return document.activeElement === this.inputTarget
  }

  setup () {
    if (!window.googleMapsIsLoaded) {
      setTimeout(this.loadAutoComplete.bind(this), 100);
    } else {
      if (!this.autocomplete) {
        this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, this.autocompleteOptions());
        this.autocomplete.addListener(
          "place_changed",
          this.handlePlaceChanged.bind(this)
        );
      }
    }
  }

  autocompleteOptions () {
    var countryCode = this.element.dataset.countryCode;
    var language = this.element.dataset.language;
    var resultTypes = this.element.dataset.types;
    var types = [];

    if (resultTypes != "") {
      types = [resultTypes];
    }

    var options = {
      componentRestrictions: {
        country: countryCode
      },
      fields: [
        "address_components",
        "geometry"
      ],
      language: language,
      types: types
    }

    return options
  }

  // This is called when a user selects a place in the dropdown.
  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    if (!place) { return }

    const addressComponents = place.address_components;

    if (this.hasPostalCodeTarget) {
      const postalCode = addressComponents.find(component => component.types.includes("postal_code"));
      this.postalCodeTarget.value = postalCode ? postalCode.long_name : "";
    }
  }

  loadAutoComplete () {
    if (!window.googleMapsIsLoaded) {
      const element = this.element;
      const { googlekey, region } = element.dataset;

      if (!document.getElementById('google-maps-sdk')) {
        var script = document.createElement('script');

        const urlOptions = `key=${googlekey}&libraries=places&callback=window.addressGoogleCallback&region=${region}`

        script.id = 'google-maps-sdk';
        script.setAttribute('async', 'true');
        script.setAttribute('defer', 'true');
        script.src = `//maps.googleapis.com/maps/api/js?${urlOptions}`;

        document.body.append(script);
      }
    }
  }

  dontSubmit (input) {
    // dont submit the form when pressing enter in the autocomplete field
    google.maps.event.addDomListener(input, "keydown", function(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
      }
    });
  }
}
