// city_picker.js
//
// Module for artists to search, and pick, cities
//
//= require 'backend/auto-complete.min.js'

import autoComplete from '../vendor/auto_complete.min';

export default function(options) {
  var inputSelector = options['input'];
  var outputSelector = options['output'];
  var onFirstSuggestion = options['onFirstSuggestion'] || function() {};

  this.preventBrowserAutocomplete = function(element) {
    setInterval(function () {
      element.attributes['autocomplete'].value = this.buildRandomString()
    }.bind(this), 250)
  }

  if (inputSelector) {
    this.preventBrowserAutocomplete(inputSelector)
  }

  this.autoComplete = new autoComplete({
    onSelect: function (event, term, renderedItem) {
      var selectedId = renderedItem.dataset.id
      if (selectedId) {
        inputSelector.value = renderedItem.dataset.val
        outputSelector.value = selectedId
      }
    },

    renderItem: function (item) {
      var city = item.city
      if (city) {
        var display_name = city.display_name || city.name

        var html = '<div class="autocomplete-suggestion" data-id=' + city.id + ' data-val="' + display_name + '">' + display_name + '</div>'
        return html
      }
    },

    selector: inputSelector,

    minChars: 2,

    source: function (term, response) {
      fetch('/cities/search.json?query=' + term)
        .then(res => res.json())
        .then(function(res) {
          response(res)

          // Store first suggestion
          if (res.length > 0) {
            var firstSuggestion  = res[0]

            outputSelector.value = firstSuggestion.city.id
            onFirstSuggestion(firstSuggestion)
          }

        })
    }
  });

  this.buildRandomString = function() {
    var result = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    return result;
  }
}
