import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "bodyInput",
    "submit",
    "titleInput"
  ]

  connect () {
    this.setupFormEventHandling()
    this.setupStarsChangedHandling()
  }

  placeholderUpdate (details) {
    const bodyInput = this.bodyInputTarget
    const titleInput = this.titleInputTarget

    const value = details.value

    if (value && value > 0) {
      titleInput.setAttribute('placeholder', details.titlePlaceholder);
      bodyInput.setAttribute('placeholder', details.bodyPlaceholder);
    }
  }

  setupFormEventHandling () {
    const form = this.element
    const submit = this.submitTarget

    form.addEventListener('ajax:beforeSend', (_event) => {
      submit.setAttribute('disabled', true);
    })

    form.addEventListener('ajax:success', (event) => {
      const xhr = event.detail[2]
      form.outerHTML = xhr.responseText;

      if (typeof(ga) != 'undefined') {
        ga(['send', 'pageview', '/reviews/create']);
      }
    })

    form.addEventListener('ajax:error', (event) => {
      const xhr = event.detail[2]
      form.outerHTML = xhr.responseText;
    })

    form.addEventListener('ajax:complete', (event) => {
      submit.removeAttribute('disabled');
    })
  }

  setupStarsChangedHandling () {
    const placeHolderUpdate = this.placeholderUpdate.bind(this)

    window.addEventListener('stars.value-changed', function(event) {
      placeHolderUpdate(event.detail)
    });
  }
}
